<template>
  <div>
    <div v-if="showContinuePlaceHolder">
      <categoriesPlaceholder :screen="screen"/>
    </div>
      <div :class="[`main-${id}`, 'home-class-main']" v-if="continueWatchData && continueWatchData.length > 0 && !showContinuePlaceHolder">
        <div class="slider-category-title" v-if="screen && screen.title">{{ localDisplayLang === "ml" ? (screen.title.mal || screen.title.ar) : screen.title.default }}</div>
        <div class="slider-header">
          <div
            class="seeall"
            @click="seeAll()"
            v-if=" (continueWatchData && continueWatchData.length > 13)">
            <div class="more"> {{ $t("More") }} </div>
            <div> <img src="@/assets/icons/rightarrow.svg" alt="more" class="seeall-arrow" /> </div>
          </div>
        </div>
        <div :class="['swiper', `ok${id}`]" style="position: relative;padding-right: 4rem;">
          <div class="swiper-wrapper" style="padding-right: 4rem;">
            <div class="swiper-slide landscape-img-view" :id="`swiper-slide-${index}`" v-for="(item, index) of continueWatchData" :key="index">
              <categoryCard
                :content="item"
                :percentProgress="item.progress"
                :absoluteProgress="item.watchedduration"
                :displayTags="false"
                :cardType="cardDisplayType"
                :playerInstance="playerInstance"
                @remove="removeDataFromFire"
                :showRemove="true"
                :showTitle="true"
                :isContinueWatch="true"
              ></categoryCard>
            </div>
          </div>
          <div class="swiper-button-next">
            <div class="next-btn-onhover"></div>
          </div>
          <div class="swiper-button-prev">
            <div class="prev-btn-onhover"></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import { store } from "@/store/store";
import {
  vLivePlayer,
  vLivePlaylistItem,
} from "/src/vue-player/vLiveWebPlayer.js";

export default {
  props: {
    screen: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
    screenId: {
      type: String,
    },    
    screenName: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      continueWatchContent: [],
      continueWatchData: [],
      slider: null,
      showContinuePlaceHolder: false,
      detailPlaylist: [],
      finalPlaylist: [],
      availabilities: [],
      subscriptions: [],
      purchases: [],
      contentProgress: null,
      completeEpisodeListing: [],
      playlistIndex: 0,
      continueData: [],
      page: 1,
      isMpegRequired: false,
      totalFirebaseResponse: null,
      displayType: 'LANDSCAPE',
      cardDisplayType: {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "100%",
          width: "100%",
          playButton: { enablePlay: true, width: "40px", height: "40px" },
      }
    };
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
    ]),
  },
  created() {
    //assigning subscription list response.
    // this.isMpegRequired = this.checkMpegRequired();
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();

      setTimeout(() => {
        if (
          this.localDisplayLang === "ara" &&
          this.continueWatchData &&
          this.continueWatchData.length < 5
        ) {
          document.getElementById("continueWatch-carousel-iw")
            ? (document.getElementById(
                "continueWatch-carousel-iw"
              ).style.textAlign = "-webkit-right")
            : "";
        }
      }, 700);
    });
  },
  updated() {
    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  mounted() {
    //To get the availability list.
    setTimeout(() => {
      if (this.availabilityList !== undefined) {
        this.availabilities = this.availabilityList;
      }
    }, 2000);
    eventBus.$on("removeFromContinueWatch", (data) => {
      this.removeDataFromFire(data);
    });

    //To fetch continue watching on app launch.
    this.checkEpisodeContinueWatch();

    //To check for newly added firebase data.
    eventBus.$on("check-new-fire-data", () => {
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();
    });

    eventBus.$on("check-for-series-route", () => {
      this.checkEpisodeContinueWatch();
    });
    this.showContinuePlaceHolder = true;
    this.constructSlider();
  },
  methods: {
    ...mapMutations(["setContinueWatchData"]),
    ...mapActions(["listContent"]),
    seeAll() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({
        name: "seeAllContinueWatch",
        params: { continueWatchData: this.totalFirebaseResponse, lang: currentLanguage },
      });
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    attachFirebaseNodes(content) {
      let index = this.continueData.findIndex((el) => {
        return el.objectid === content.objectid;
      });

      if (index > -1) {
        content.firebaseKeys = this.continueData[index];
      }
    },

    constructSlider() {
      if (this.continueWatchData && this.continueWatchData.length > 0) {
        let breakpoints = {
          300: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 3 : 2,  // Adjust the number of posters for mobile devices
            spaceBetween: 5  // Adjust the space between posters for mobile devices
          },
          420: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 2.5 : 1.7,  // Adjust the number of posters for mobile devices
            spaceBetween: 5  // Adjust the space between posters for mobile devices
          },
          500: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 3.5 : 2,  // Adjust the number of posters for mobile devices
            spaceBetween: 7  // Adjust the space between posters for mobile devices
          },
          630: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 2.8,  // Adjust the number of posters for mobile devices
            spaceBetween: 10  // Adjust the space between posters for mobile devices
          },
          768: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 3,  // Adjust the number of posters for mobile devices
            spaceBetween: 12  // Adjust the space between posters for mobile devices
          },

          992: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 5.2 : 4.5,
            spaceBetween: 20
          },

          1024: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 6.3 : 4.7,
            spaceBetween: 20
          },

          1500: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 7.3 : 6.7,
            spaceBetween: 10
          },
          1800: {
            slidesPerView: this.displayType == 'PORTRAIT' ? 8.3 : 7.1,
            spaceBetween: 10
          }
        }

        // alert(`.ok${this.id}`)
        this.$nextTick(() => {
          let swiper = new window.Swiper(`.ok${this.id}`, {
            slidesPerView: 2,  // Set the number of slides directly for testing
            cssMode: true,
            lazy: true,
            spaceBetween: 10,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            on: {
              init: function () {
                
              },
            },
            breakpoints,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
          swiper.init();
        })
      }
    },
    removeDataFromFire(data) {
      // this.showContinuePlaceHolder = true;
      this.removeFromContinueWatch(data);
      this.continueWatchData = this.continueWatchData.filter((item, i) => {
        return data.objectid!== item.objectid;
      })
      // this.slider = null;
      // setTimeout(() => {
      //   this.checkEpisodeContinueWatch();
      // }, 1000);
    },
    checkEpisodeContinueWatch() {
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "",
        response = null,
        episodeObjs = null,
        newEpisodeObj = null,
        segEpisodeArr = null,
        movieObjs = null;

      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
        firebase
          .database()
          .ref(path)
          .once("value")
          .then((snapshot) => {
            response = this.collectionsToArray(snapshot.val());
            //this.totalFirebaseResponse = response

            // Filter the movies for continue watch
            movieObjs = response.filter((element) => {
              return (
                element.category === "MOVIE" &&
                element.status === "INPROGRESS" &&
                element.watchedduration
              );
            });
            // Filter only the series with episodes key
            episodeObjs = response.filter((element) => {
              return element.episodes;
            });

            // Convert the collection to an array
            newEpisodeObj = [...this.collectionsToArray(episodeObjs)];

            // Create an array of only the episodes of the particular series
            newEpisodeObj.forEach((element) => {
              segEpisodeArr = this.collectionsToArray(element.episodes);
              //assign series title to each episode
              segEpisodeArr.forEach((el) => {
                el.title = element.title;
              });

              // Sort episodes based on timestamp
              segEpisodeArr = segEpisodeArr.sort((a, b) => {
                return b.updatedat - a.updatedat;
              });

              // Pick the first index after sorting as it will be the latest episode watched
              this.continueWatchContent.push(segEpisodeArr[0]);
            });
            this.continueWatchDataTemp = [
              ...this.continueWatchContent,
              ...movieObjs,
            ];

            this.continueWatchDataTemp = this.continueWatchDataTemp.filter(
              (item) => {
                return item.status ==="INPROGRESS" && item.watchedduration;
              }
            );
            this.continueWatchDataTemp = this.continueWatchDataTemp.sort(
              (a, b) => {
                return b.updatedat - a.updatedat;
              }
            );
            this.totalFirebaseResponse = this.continueWatchDataTemp;
            let Data = this.continueWatchDataTemp;
            this.continueWatchDataTemp =
              this.continueWatchDataTemp.length > 15
                ? this.continueWatchDataTemp.slice(0, 15)
                : this.continueWatchDataTemp;
								try{
										this.checkLanguage();
									}
								catch(error){
								console.log(error);
								}
          });
      }
    },

    checkLanguage() {
      const seriesObjIds = [];
      const moviesObjIds = [];

      this.continueWatchDataTemp.forEach((element) => {
        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } else if (element.category == "MOVIE") {
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });

      let seriesPromise;
      let moviesPromise;

      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
        };

        seriesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((item, dIndex) => {
                if (item.objectid === content.objectid) {
                  let duration = content.duration;
                  let watched = content.watchedduration;
                  let progress = (watched / duration) * 100;
                  this.continueWatchDataTemp[cIndex] = {
                    ...item,
                    status : content.status,
                    updatedat: content.updatedat,
                    watchedduration: content.watchedduration,
                    progress: (progress > 0 && progress < 100) ? progress : null
                  };
                }
              });
            });
            resolve(this.continueWatchDataTemp);
          });
        });
      }

      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
        };


        moviesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((item, dIndex) => {
                if (item.objectid === content.objectid) {
                  let duration = content.duration;
                  let watched = content.watchedduration;
                  let progress = (watched / duration) * 100;
                  this.continueWatchDataTemp[cIndex] = {
                    ...item,
                    status : content.status,
                    updatedat: content.updatedat,
                    watchedduration: content.watchedduration,
                    progress: (progress > 0 && progress < 100) ? progress : null
                  };
                }
              });
            });

            resolve(this.continueWatchDataTemp);
          });
        });
      }
      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {

        this.continueWatchData = finalData[1] ? finalData[1] : finalData[0];

        if (this.continueWatchData && this.continueWatchData.length > 0) {
          this.continueWatchData = this.continueWatchData.filter((el) => {
            return el.status === "INPROGRESS";
         });

          this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }

        this.showContinuePlaceHolder = false;
        this.constructSlider();
      });
    },

    getPoster(content, type) {
      // debugger
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (element.quality === "SD" || element.quality === "HD") && element.postertype === type;
          });
          let img = 0;
          if (index > -1 && Array.isArray(content.poster[index]?.filelist)) {
            content.poster[index].filelist?.forEach(element => {
              // if (element.quality == 'LOW') {
              // return element.filename
              img = element.filename;
              // }
            });
            if (img) {
              return img
            } else {
              if (type == "LANDSCAPE") {
                return this.placeholder_lands;
              }
              return this.placeholder_port;
            }
          } else {
            if (type == "LANDSCAPE") {
              return this.placeholder_lands;
            }
            return this.placeholder_port;
          }
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      } else if (content.thumbnail) {
        return this.placeholder_port;
      } else {
        if (type == "LANDSCAPE") {
          return this.placeholder_lands;
        }
        return this.placeholder_port;
      }
    },
  },
  beforeDestroy() {
    if(this.playerInstance) {
      this.playerInstance.deregisterEvent("contentended");
    }

    eventBus.$off("check-for-series-route");
    eventBus.$off("send-episode-list");
    eventBus.$off("check-new-fire-data");
  },
  components: {
    categoriesPlaceholder: () => import(/* webpackchunkname: continueWatchingPlaceholder */ "@/components/placeholders/categoriesPlaceholder.vue"),
    categoryCard: () => import(/* webpackChunkName: "genreDrop" */ "@/components/Templates/categoryCard.vue"),
    Slider: () => import('@/themeearth/components/dashboard/sliderCarousel/Slider.vue'),
  },
  mixins: [Utility, FirebaseActions],
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

:root {
  --blue: #1e90ff;
  --white: $clr-saina-light-dark-txtf;
  --VERTICAL-CARD-HOVER-SCALE-X: 0.8;
  --VERTICAL-CARD-HOVER-SCALE-Y: 0.8;
}

.swiper {
  width: 100%;
  height: 100%;
}

.landscape-img-view {
  aspect-ratio: 16 / 9;
}

.portrait-img-view {
  aspect-ratio: 2 / 3;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #282828;
  /* background: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 16rem;
  border-radius: 8px;
  border: 1px solid #282828;
  // pointer-events: none;
  z-index: 9;
  cursor: pointer;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.slider-category-title {
  font-size: 1.3rem;
  margin: 0.8rem 0.1rem;
  color: $clr-saina-light-dark-txt;
}

.swiper-button-next-overlay {
  height: 15rem;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.0) 90%);
  position: absolute;
  width: 3rem;
  top: 0px;
  display: block;
  z-index: 9999;
  // pointer-events: none;
  margin-top: auto !important;
}

::after {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.swiper-button-next-overlay[aria-disabled="true"] .swiper-button-prev-overlay[aria-disabled="true"] {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.85) 90%);
}

.swiper-button-prev-overlay {
  height: 16rem;
  position: absolute;
  width: 3rem;
  top: 0px;
  right: 0;
  display: block;
  z-index: 9999;
  margin-top: auto !important;
}

@keyframes kdUbkV2MkpUdd4wNQxnlN {
  100% {
    opacity: 1;
    transform: scale(1);

  }

  0% {
    opacity: 0;
    transform: scale(0.5);

  }
}

.onhover-poster {
  position: absolute;
  z-index: 999;
  top: -5%;
  /* Initially position below the parent element */
  cursor: pointer;

  .hover-card {
    width: 22rem;
    height: 26rem;
    background: #101113;
    transform-origin: center center;
    animation: kdUbkV2MkpUdd4wNQxnlN 0.6s ease-in;
    position: relative;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 3px #00000073;

    figure {
      width: 100%;
      height: 50%;

      .image__item_video {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0 0;
        height: 12rem;
        object-fit: cover;

      }

      .image__item {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0;
      }

      .related-meta-data {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .related-action-btns {
          display: flex;
          gap: 1rem;
        }

        .related-meta-data-details {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .fig-title {
            font-weight: 500;
            font-size: 1rem;
          }

          .fig-details {
            display: flex;
            gap: 0.8rem;
            flex-direction: column;

            .main-info {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
              align-items: center;

              .publish-year,
              .duration {
                color: #aaaaaa;
              }

              .pgrating {
                border: #3b3a3a 0.8px solid;
                padding: 0.1rem 0.4rem;
                border-radius: 0.2rem;
                color: #ffffff;
              }
            }

            .main-genre {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
            }

            .main-genre .sub-genre2 p::before,
            .main-genre .sub-genre3 p::before {
              content: "• ";
              position: relative;
              right: 0.4rem;
            }
          }

          .fig-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            color: rgba(170, 170, 170, 1);
            font-weight: 400;
            font-size: 0.8rem;
            height: 3.5rem;
          }
        }
      }
    }
  }

}

.mute-unmute-btn-slider {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  z-index: 999999;
}

.home-class-main {
  //margin-left: 1rem;
  position: relative;
}


.next-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: .2;
  border-radius: 10px;

}

.prev-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: .1;
  border-radius: 10px;
}

.slider-header {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0px 0.7rem;

  .seeall {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.75px;
    padding: 0 5px;
    transition: 0.1s;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0rem;
    margin-bottom: 3rem;

    &:hover {
      transform: scale(1.05);
      color: $clr-light-gd3;
    }
  }

  .seeall-arrow {
    position: relative;
    top: 0.22rem;
    width: 1rem;
    height: 1.6rem;
  }

  .slider-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .slider-head-btns {
      margin-top: 3px;

      .slider-controls {
        button {
          border: none;
          background: transparent;


          cursor: pointer;

          img {
            height: 1.6rem;
            width: auto;
          }

          &:disabled {
            opacity: 0.3;
            display: flex;
          }

          &:focus {
            outline: none;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.slider-content {
  .content-slider {
    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 786px) {
  .swiper-button-next, .swiper-button-prev, .more {
    display: none;
  }
  .slider-category-title, .seeall {
    font-size: 0.8rem !important;
    margin: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }

}

@media screen and (min-width: 786px) and (max-width: 1023px) {
 .slider-category-title, .seeall {
    font-size: 0.9rem !important;
    margin: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
 }
 .badge {
  position: absolute;
  padding: 0.2rem;
  background:red;
  top: 5px; 
  left: 5px;
  font-size: 0.5rem;
  border-radius: 0.25rem
 }
 .next-btn-onhover, .prev-btn-onhover {
    width: 2.5rem;
    height: 5rem;
  }
  .seeall-arrow {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
